<template>
    <!-- 组织管理 -->
    <div class="department-admin">
        <div class="accountSearch">
            <div>
                <el-button @click="addDoorClick" class="titleButton" type="primary" icon="el-icon-download">添加组织</el-button>
            </div>
        </div>
        <div class="accountTableC">
            <div class="companyTable">
                <el-table 
                v-loading="loading" 
                :data="deviceData.elements" 
                style="width: 100%" height="100%" 
                row-key="id"
                lazy
                :load="load"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                    <el-table-column prop="id" label="组织ID" align="center" min-width="60">
                    </el-table-column>
                    <el-table-column prop="name" label="组织名称" min-width="180">
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" align="center" min-width="160">
                    </el-table-column>
                    <el-table-column prop="createdAt" label="添加时间" align="center" min-width="200">
                    </el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="editClick(scope.row, scope.$index)" type="text">编辑</el-button>
                            <el-button @click="deleteClick(scope.row, scope.$index)" type="text">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination :current-page="currentPage" :page-size="deviceData.pageSize" @current-change="handleCurrentChange" background layout="total, prev, pager, next" :total="deviceData.totalRecords">
            </el-pagination>
        </div>
        <!-- 弹窗 添加/编辑组织 -->
        <addEditDepartment v-if="addEditAccountShow" 
        @cancelAgent-fun="cancelAgentFun" 
        @deterAgent-fun="deterAgentFun" :catdTitle="catdTitle"
        :agentListA="deviceData.elements"
        :agentList="catdTitle === '添加组织'?{}:agentRow"
        ></addEditDepartment>
        <!-- 弹窗 删除-有子集 -->
        <deleteDepar v-if="deleteDeparShow"
        @subset-cancel="subsetCancel"
        @subset-OK="subsetOK"
        :agentListA="deviceData.elements"
        :catdTitle="catdTitle"
        :deleteID="agentRow.id"
        :name="deleteName"
        ></deleteDepar>
        <!-- 弹窗 删除 -->
        <deletePop v-if="deletePopShow"
        @deletePop-fun="deletePopFun"
        @determineDel-fun="determineDelFun"
        :catdTitle="catdTitle"
        :companyName="agentRow.name"
        :name="deleteName"
        ></deletePop>
    </div>
</template>
<script>
    import addEditDepartment from '../../components/addEditDepartment'
    import deleteDepar from '../../components/deleteDepar'
    import deletePop from '../../components/deletePop'

    export default {
        name: 'department-admin',
        components: { addEditDepartment, deleteDepar, deletePop },
        data() {
            return {
                loading: false,
                // 是否显示 弹窗 添加/编辑组织
                addEditAccountShow: false,
                // 是否显示 弹窗 删除-有子集
                deleteDeparShow:false,
                // 是否显示 弹窗 删除
                deletePopShow: false,
                // 搜索框 值
                input: '',
                // 拉取到的 表格数据
                deviceData: {},
                // 当前页数
                currentPage: 1,
                //列表-开始位置
                start: 1,
                // 弹窗 标题
                catdTitle: '',
                // 被选中下标
                agentRow: null,
                deleteName: '的组织信息',
            }
        },
        created() {
            this.equipmentList()
        },
        methods: {
            // 加载子节点数据的函数
            load(tree, treeNode, resolve) {
        setTimeout(() => {
          resolve([
            {
              id: 31,
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄'
            }, {
              id: 32,
              date: '2016-05-01',
              name: '王小虎',
              address: '上海市普陀区金沙江路 1519 弄'
            }
          ])
        }, 1000)
      },
            // 弹窗 添加/编辑组织 取消
            cancelAgentFun() {
                this.addEditAccountShow = false
            },
            // 弹窗 添加/编辑组织 提交
            deterAgentFun() {
                this.addEditAccountShow = false
                this.equipmentList()
            },
            // 点击 添加组织
            addDoorClick() {
                this.catdTitle = '添加组织'
                this.addEditAccountShow = true
            },
            // 点击 编辑组织
            editClick(val) {
                this.agentRow = val
                this.catdTitle = '编辑组织'
                this.addEditAccountShow = true
            },
            // 组织管理 列表
            equipmentList() {
                this.loading = true

                this.$instance
                    .get('/v1/attendance/list', {
                        params: {
                            pageNum: this.currentPage - this.start,
                            pageSize: 10,
                        },
                    })
                    .then(res => {
                        if (res.data.status === 200) {
                            this.loading = false
                            this.deviceData = res.data.data
                        }
                    })
                    .catch(error => {
                        console.log('error: ', error)
                    })
            },
            // 点击分页-当前页
            handleCurrentChange(val) {
                this.currentPage = val
                // 搜索并拉取列表
                this.equipmentList()
            },
            // 点击 删除
            deleteClick(val) {
                this.agentRow = val
                this.catdTitle = '删除组织'
                this.deletePopShow = true
            },
            // 弹窗 删除-有子集 取消
            subsetCancel(){
                this.deleteDeparShow=false
            },
            // 弹窗 删除-有子集 确定
            subsetOK(parentId){
                this.deletePost(parentId)
            },
            // 弹窗 删除 取消
            deletePopFun() {
                this.deletePopShow = false
            },
            // 弹窗 删除 确定
            determineDelFun(){
                this.deletePost()
            },
            // 弹窗 删除
            deletePost(parentId) {
                let deptId=''
                if(parentId){
                    deptId='&&deptId='+parentId
                }
                this.$instance
                    .post(
                        '/v1/attendance/del?id=' +
                            this.agentRow.id+deptId,
                        {}
                    )
                    .then(res => {
                        if (res.data.status === 200) {
                            this.deletePopShow = false
                            this.deleteDeparShow=false
                            // 搜索并拉取列表
                            this.equipmentList()
                            this.$message({
                                message: this.catdTitle + '成功！',
                                type: 'success',
                            })
                        }else if(res.data.status === 901){
                            this.deletePopShow = false
                            this.deleteDeparShow=true
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
        },
    }
</script>
<style lang="scss" scoped>
    .department-admin {
        width: calc(100% - 40px);
        margin: 20px;
        background-color: #fff;
        // 搜索
        & ::v-deep .accountSearch {
            width: 100%;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 20px;
            box-sizing: border-box;
            border-bottom: 1px #f0f0f0 solid;
            .el-button--primary {
                line-height: 32px;
                padding: 0 12px;
                border: unset;
            }
            .titleSearch {
                display: flex;
                .el-select > .el-input,
                .el-input {
                    margin-right: 6px;
                }
                .el-select > .el-input {
                    width: 150px;
                }
                .el-input {
                    width: 240px;
                    .el-input__inner {
                        height: 32px;
                        line-height: 32px;
                    }
                    .el-input__suffix {
                        i {
                            line-height: 32px;
                        }
                    }
                }
            }
        }
        .accountTableC {
            width: calc(100% - 40px);
            height: calc(100% - 144px);
            margin: 20px;
            // 表格
            & ::v-deep .companyTable {
                height: 100%;
                border: 1px #f0f0f0 solid;
                overflow: auto;
                .el-table td,
                .el-table th {
                    padding: 4px 0;
                }
            }
        }
    }
</style>
