<template>
    <!-- 删除组织-有子集 -->
    <div class="deleteDepar">
        <div class="mask"></div>
        <div  @click.stop class="addEditAgentCard">
            <!-- 头部 -->
            <div class="cardHeader">
                <div>{{ catdTitle }}</div>
                <i class="el-icon-close" @click="subsetCancel"></i>
            </div>
            <el-form :model="myForm" ref="myForm">
                <div class="tips">所删除的组织下有人员绑定，请选择移动到的目标组织</div>
                <el-form-item label="目标一级组织">
                    <el-select 
                        @change="typeChange"
                        v-model="myForm.deptId" 
                        placeholder="请选择目标一级组织"
                    >
                        <el-option v-for="(itme, index) in agentListAA" :key="index" :label="itme.name" :value="itme.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="目标二级组织">
                    <el-select
                        @change="typeChange2" 
                        v-model="myForm.deptSonId" 
                        placeholder="请选择目标二级组织"
                    >
                        <el-option v-for="(itme, index) in enterpriseATwo" :key="index" :label="itme.name" :value="itme.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="button">
                <el-button @click="subsetCancel">取 消</el-button>
                <el-button type="primary" @click="subsetOK">移动并删除</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'deleteDepar',
        props: {
            // 标题
            catdTitle: {
                type: String,
                default: '编辑组织',
            },
            // 信息
            agentListA: {
                type: Array,
                default() {
                    return []
                },
            },
            // 删除的组织ID
            deleteID:{
                type:Number,
                default:null
            }
        },
        data() {
            return {
                // 表单
                myForm: {},
                enterpriseATwo: [],
            }
        },
        created() {
            var objString = JSON.stringify(this.agentListA);
            this.agentListAA = JSON.parse(objString);
            this.agentListAA.map((val,index)=>{
                if(val.id===this.deleteID){
                    this.agentListAA.splice(index,1)
                }
            })
        },
        methods: {
            // 所属一级组织-选中值发生变化时触发
            typeChange(value) {
                this.enterpriseATwo=[]
                this.myForm.deptSonId=null
                this.agentListAA.map((val) => {
                    if (val.id === value&&val.children.length) {
                        this.enterpriseATwo=val.children
                        this.enterpriseATwo.map((value,index)=>{
                            if(value.id===this.deleteID){
                                this.enterpriseATwo.splice(index,1)
                            }
                        })
                    }
                })
            },
            typeChange2(){
                this.$forceUpdate()
            },
            // 点击取消
            subsetCancel() {
                this.$emit('subset-cancel')
            },
            // 点击提交
            subsetOK() {
                let deptId=null
                if(this.enterpriseATwo.length){
                    deptId=this.myForm.deptSonId
                }else{
                    deptId=this.myForm.deptId
                }
                this.$emit('subset-OK',deptId)
            },
        },
    }
</script>
<style lang="scss" scoped>
    .deleteDepar {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
        }
        // 弹窗卡片
        & ::v-deep .addEditAgentCard {
            width: 460px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            border-radius: 6px;
            // 头部
            .cardHeader {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 18px;
                padding: 0 40px;
                box-sizing: border-box;
                border-radius: 6px 6px 0 0;
                // 头部-icon
                .el-icon-close {
                    font-size: 24px;
                }
            }
            .el-form {
                padding: 30px 40px 10px;
                border-top: 1px #f0f0f0 solid;
                border-bottom: 1px #f0f0f0 solid;
                .tips{
                    color: #cd3636;
                    margin-bottom: 30px;
                    text-align: center;
                }
                .el-form-item {
                    margin-bottom: 20px;
                    .el-form-item__label {
                        width: 96px !important;
                        padding-right: 10px;
                        line-height: 32px;
                    }
                    .el-input__inner {
                        line-height: 32px !important;
                    }
                    .el-form-item__content {
                        margin-left: 96px !important;
                        line-height: 32px;
                        .el-select {
                            width: 100%;
                        }
                        input {
                            height: 32px;
                        }
                        i {
                            line-height: 32px;
                        }
                    }
                }
            }
        }
        .button {
            padding: 20px 40px;
            display: flex;
            justify-content: flex-end;
            .el-button {
                line-height: 30px;
                padding: 0 20px;
            }
        }
    }
</style>
